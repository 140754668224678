<template>
    <div>
        {{ row.item.sales_price | priceFormat }} &euro;
    </div>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'refreshTable': Function
    },
}
</script>